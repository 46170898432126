import { Close } from "@mui/icons-material";
import {
  Alert as MuiAlert,
  AlertProps,
  AlertTitle,
  IconButton,
  Snackbar
} from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { SnackbarState } from "../../store/store";

export type SnackbarAlertSeverity = AlertProps["severity"];

export const Alert = React.forwardRef(function SnackbarAlert(
  props: AlertProps,
  ref: React.Ref<HTMLDivElement>
) {
  return <MuiAlert {...props} elevation={6} ref={ref} />;
});

export function SnackbarAlert() {
  const [{ severity, ...snackbarProps }, setSnackbar] = useRecoilState(
    SnackbarState
  );

  const clearSnackbar = () =>
    setSnackbar(snackBar => ({ ...snackBar, open: false }));

  const handleClose = (
    _: React.SyntheticEvent<Event> | Event,
    reason: string
  ) => {
    if (reason !== "clickaway") clearSnackbar();
  };

  return (
    <Snackbar
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      {...snackbarProps}
      onClose={handleClose}
    >
      <Alert
        sx={{
          maxHeight: "450px",
          padding: "1rem",
          boxShadow: "0px 4px 4px 0px #00000026"
        }}
        severity={severity}
        action={
          !snackbarProps.autoHideDuration && (
            <IconButton onClick={clearSnackbar} sx={{ marginTop: "-0.5rem" }}>
              <Close />
            </IconButton>
          )
        }
      >
        <AlertTitle sx={{ fontWeight: "bold", padding: 0 }}>
          {snackbarProps.title ?? snackbarProps.message}
        </AlertTitle>
        {snackbarProps.title && snackbarProps.message
          ? snackbarProps.message
          : null}
      </Alert>
    </Snackbar>
  );
}
