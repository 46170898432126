import { useEffect, useMemo, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { CircularProgress } from "@mui/material";

import { Chart } from "../components/Chart/Chart";
import { LeafletMap } from "../components/Leaflet/Leaflet";
import { DataTable } from "../components/DataTable/DataTable";

import "./ContentPage.css";

export function ContentPage() {
  const [googleKeyValidationStatus, setGoogleKeyValidationStatus] = useState<
    boolean
  >(false);
  const [validationLoadingStatus, setValidationLoadingStatus] = useState<
    boolean
  >(false);

  const loader = useMemo(() => {
    return new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_KEY!,
      version: "weekly",
      libraries: ["visualization"]
    });
  }, []);

  useEffect(() => {
    setValidationLoadingStatus(true);
    loader
      .load()
      .then(() => setGoogleKeyValidationStatus(true))
      .catch(e => console.error(e))
      .finally(() => setValidationLoadingStatus(false));
  }, [loader]);

  return (
    <div className="contentPage">
      {validationLoadingStatus && (
        <CircularProgress
          size={100}
          sx={{ marginLeft: "-50px", marginTop: "-50px" }}
          className="loadingStatus"
          color="primary"
        />
      )}
      <div className="contentPage__map">
        {googleKeyValidationStatus ? <LeafletMap /> : null}
      </div>
      <div className="contentPage__report">
        <div className="report">
          <div className="report__header">
            <h1 className="type-base-bold lg:type-xl-bold">LCOE Calculator</h1>
            <div className="color-stone">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 25"
              >
                <path
                  fill="currentColor"
                  d="M9 20.5c-.55 0-1.02083-.1958-1.4125-.5875C7.19583 19.5208 7 19.05 7 18.5s.19583-1.0208.5875-1.4125C7.97917 16.6958 8.45 16.5 9 16.5s1.0208.1958 1.4125.5875c.3917.3917.5875.8625.5875 1.4125s-.1958 1.0208-.5875 1.4125C10.0208 20.3042 9.55 20.5 9 20.5Zm6 0c-.55 0-1.0208-.1958-1.4125-.5875C13.1958 19.5208 13 19.05 13 18.5s.1958-1.0208.5875-1.4125C13.9792 16.6958 14.45 16.5 15 16.5s1.0208.1958 1.4125.5875c.3917.3917.5875.8625.5875 1.4125s-.1958 1.0208-.5875 1.4125c-.3917.3917-.8625.5875-1.4125.5875Zm-6-6c-.55 0-1.02083-.1958-1.4125-.5875C7.19583 13.5208 7 13.05 7 12.5s.19583-1.0208.5875-1.4125C7.97917 10.6958 8.45 10.5 9 10.5s1.0208.1958 1.4125.5875c.3917.3917.5875.8625.5875 1.4125s-.1958 1.0208-.5875 1.4125C10.0208 14.3042 9.55 14.5 9 14.5Zm6 0c-.55 0-1.0208-.1958-1.4125-.5875C13.1958 13.5208 13 13.05 13 12.5s.1958-1.0208.5875-1.4125C13.9792 10.6958 14.45 10.5 15 10.5s1.0208.1958 1.4125.5875c.3917.3917.5875.8625.5875 1.4125s-.1958 1.0208-.5875 1.4125c-.3917.3917-.8625.5875-1.4125.5875Zm-6-6c-.55 0-1.02083-.19583-1.4125-.5875C7.19583 7.52083 7 7.05 7 6.5s.19583-1.02083.5875-1.4125C7.97917 4.69583 8.45 4.5 9 4.5s1.0208.19583 1.4125.5875C10.8042 5.47917 11 5.95 11 6.5s-.1958 1.02083-.5875 1.4125C10.0208 8.30417 9.55 8.5 9 8.5Zm6 0c-.55 0-1.0208-.19583-1.4125-.5875C13.1958 7.52083 13 7.05 13 6.5s.1958-1.02083.5875-1.4125C13.9792 4.69583 14.45 4.5 15 4.5s1.0208.19583 1.4125.5875C16.8042 5.47917 17 5.95 17 6.5s-.1958 1.02083-.5875 1.4125C16.0208 8.30417 15.55 8.5 15 8.5Z"
                />
              </svg>
            </div>
          </div>
          <DataTable />
          <Chart />
        </div>
      </div>
    </div>
  );
}
