import { Dot } from "recharts";

interface CustomDotProps {
  active?: boolean | null;
  payload?: any;
  onMouseEnter: (payload: any, props: any) => void;
  onMouseLeave: () => void;
}

export function CustomDot({
  active,
  onMouseEnter,
  onMouseLeave,
  ...rest
}: CustomDotProps) {
  if (rest.payload && rest.payload.lowestPoint) {
    return (
      <Dot
        {...rest}
        r={7}
        onMouseOver={() => onMouseEnter(rest.payload, rest)}
        onMouseOut={onMouseLeave}
      />
    );
  }

  return null;
}
