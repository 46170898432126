import { useCallback, useMemo } from "react";
import { useRecoilState } from "recoil";

import { DataTableCard } from "./components/DataTableCard";

import { DatasetSelector, MapMarkersState } from "../../store/store";
import { CostsMarker } from "../../types";

import "./DataTable.css";

export interface ResultI {
  color: string;
  content: CostsMarker;
}

export function DataTable() {
  const [datasets, setDatasets] = useRecoilState(DatasetSelector);
  const [mapMarkers, setMapMarkers] = useRecoilState(MapMarkersState);

  const pointSets: ResultI[] = useMemo(() => {
    const results: ResultI[] = [];

    for (let set of datasets) {
      const lowestSet = set.data.find(point => point.lowestPoint);

      if (lowestSet) {
        results.push({
          color: set.tableColor,
          content: {
            lcoe: lowestSet.lcoe,
            gradient: lowestSet.gradient,
            temperature: lowestSet.temperature,
            depth: lowestSet.depth,
            depthToBasement: lowestSet.depthToBasement,
            location: lowestSet.location
          }
        });
      }
    }

    return results;
  }, [datasets]);

  const introPanel = useMemo(() => {
    if (datasets.length === 0) {
      return (
        <div className="dataTable__intro">
          <h2 className="type-sm-bold mb-4">How it works</h2>
          <div className="dataTable__intro__content bg-stone-25 color-light-green">
            <p className="type-sm lg:type-base">
              Place pins on the map to see the levelized cost of Quaise energy
              for up to six US locations at a time.
            </p>
            <p className="type-sm lg:type-base">
              The levelized cost of energy (LCOE) represents the cost of
              generating electricity over the lifetime of a system. It's
              calculated by dividing costs (capital, operations, and
              maintenance) by the amount of electricity generated. LCOE is the
              industry standard for comparing energy costs across different
              technologies.
            </p>
            <p className="type-sm lg:type-base">
              <a
                className="link-lightTheme text-darkGreen leading-snug tracking-wide link-secondary"
                href="/lcoe"
              >
                Learn more about Quaise LCOE
              </a>
            </p>
          </div>
        </div>
      );
    }
  }, [datasets.length]);

  const handleRemove = useCallback(
    (index: number) => {
      // remove the dataset
      const newDatasets = datasets.filter((_, i) => i !== index);
      setDatasets(newDatasets);

      // remove the marker
      const newMarkers = mapMarkers.filter((_, i) => i !== index);
      setMapMarkers(newMarkers);
    },
    [datasets, setDatasets, mapMarkers, setMapMarkers]
  );

  return (
    <div className="dataTable">
      {introPanel}
      {datasets.length > 0 && (
        <div>
          <h2 className="type-sm-bold mb-4">Location stats</h2>
          <div className="hSlider">
            {pointSets.map((set, index) => (
              <div key={index} className="hSlider__item">
                <DataTableCard
                  color={set.color}
                  content={set.content}
                  onClearClick={() => handleRemove(index)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
