import { useCallback, useEffect, useMemo, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useRecoilValue, useRecoilState } from "recoil";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import { GeoJsonObject } from "geojson";

import L from "leaflet";

import { ColorState, MapMarkersState } from "../../store/store";
import { useMarker } from "../../hooks/useMarker";
import { usePolygons } from "../../hooks/usePolygons";
import { Location } from "../../types";
import { getColor } from "./utils";

import "leaflet/dist/leaflet.css";
import "./Leaflet.css";

export function LeafletMap() {
  const { chartColor } = useRecoilValue(ColorState);
  const [mapMarkers, setMapMarkers] = useRecoilState(MapMarkersState);
  const [loading, setLoading] = useState<boolean>(false);
  const { data, isPending } = usePolygons();
  const { mutateAsync } = useMarker();

  const polygons = useMemo(() => {
    if (data) return data;
    return {};
  }, [data]);

  const addMarker = useCallback(
    (location: Location, callback: () => void) => {
      setLoading(true);
      mutateAsync({ location })
        .then(callback)
        .catch(e => console.error(e))
        .finally(() => setLoading(false));
    },
    [mutateAsync]
  );

  const handleAdd = useCallback(
    (map: L.Map, location: Location) => {
      if (loading) return;

      let newState = [...mapMarkers];

      if (newState.length === 6) {
        // Remove the first DOM element from the array and the document
        const firstElement = newState.shift(); // Remove and get the first element
        if (firstElement) {
          firstElement.remove(); // Remove the element from the DOM if it exists
        }
      }

      // close popup
      map.closePopup();

      addMarker(location, () => {
        // add the marker
        const marker = L.marker(location, {
          icon: L.divIcon({
            html: `
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 57.5">
  						<g data-name="Group 1">
    						<path fill="#fff" d="M23 57.5Q11.428 47.653 5.714 39.208T0 23.575Q0 12.794 6.936 6.4A22.975 22.975 0 0 1 23 0a22.975 22.975 0 0 1 16.064 6.4Q46 12.794 46 23.575q0 7.188-5.714 15.633T23 57.5Z" data-name="Path 2"/>
    						<path fill="${chartColor}" d="M23 28.221a4.858 4.858 0 0 0 4.844-4.844A4.858 4.858 0 0 0 23 18.533a4.859 4.859 0 0 0-4.844 4.844A4.858 4.858 0 0 0 23 28.221Zm0 24.221q-9.749-8.3-14.563-15.411t-4.814-13.17q0-9.083 5.843-14.472A19.356 19.356 0 0 1 23 4a19.356 19.356 0 0 1 13.534 5.389q5.843 5.389 5.843 14.472 0 6.055-4.814 13.17T23 52.443Z" data-name="Path 1"/>
  						</g>
						</svg>
						`,
            className: "iconContainer",
            iconSize: [36, 36],
            iconAnchor: [18, 36]
          })
        });

        // add the marker to the map
        marker.addTo(map);

        // update store with new marker
        newState.push(marker);

        // update the store
        setMapMarkers(newState);
      });
    },
    [chartColor, loading, setMapMarkers, addMarker, mapMarkers]
  );

  const geoJsonlayerStyle = useCallback(
    (feature: any) => ({
      fillColor: getColor(feature.properties.GRADIENT),
      weight: 2,
      opacity: 1,
      color: "transparent",
      dashArray: "3",
      fillOpacity: 0.4 // Use state variable here
    }),
    []
  );

  const parseGeoLocation = (geoLocateResult: any) => {
    let locationName = "Selected Location";
    if (geoLocateResult && geoLocateResult.results) {
      for (const result of geoLocateResult.results) {
        const city = result.address_components.find(
          (component: { types: string | string[] }) =>
            component.types.includes("locality")
        );
        const state = result.address_components.find(
          (component: { types: string | string[] }) =>
            component.types.includes("administrative_area_level_1")
        );
        const county = result.address_components.find(
          (component: { types: string | string[] }) =>
            component.types.includes("administrative_area_level_2")
        );
        if (city && state) {
          locationName = `${city.long_name}, ${state.short_name}`;
          break;
        } else if (county && state) {
          locationName = `${county.long_name}, ${state.long_name}`;
        }
      }
    }

    return locationName;
  };

  const reverseGeoLocate = async (location: Location) => {
    // set loading to true
    setLoading(true);

    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&sensor=true&key=${process.env.REACT_APP_GOOGLE_KEY}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    const locationName = parseGeoLocation(data);

    // set loading to false
    setLoading(false);

    return {
      lat: location.lat,
      lng: location.lng,
      name: locationName
    };
  };

  const ClickHandler = () => {
    const map = useMap(); // Hook to access the map instance

    useEffect(() => {
      // Event listener for click events
      const handleClick = async (e: L.LeafletMouseEvent) => {
        if (loading) return;

        // If click event is not in the GeoJSON layer
        // then ignore the event
        if (
          !(e.originalEvent.target as HTMLElement)?.classList?.contains(
            "leaflet-interactive"
          )
        ) {
          return;
        }

        // Format lattitude with cardinal direction
        const displayLatitude =
          e.latlng.lat > 0
            ? `${e.latlng.lat.toFixed(4)}° N`
            : `${Math.abs(e.latlng.lat).toFixed(4)}° S`;
        const displayLongitude =
          e.latlng.lng > 0
            ? `${e.latlng.lng.toFixed(4)}° E`
            : `${Math.abs(e.latlng.lng).toFixed(4)}° W`;

        const location = await reverseGeoLocate(e.latlng);

        // create the popup HTML
        const popupHTML = document.createElement("div");

        // location name
        const cityState = document.createElement("div");
        cityState.className = "title color-green";
        cityState.innerHTML = location.name;
        popupHTML.appendChild(cityState);

        // latitude and longitude
        const latlng = document.createElement("div");
        latlng.className = "description color-light-green";
        latlng.innerHTML = `${displayLatitude}, ${displayLongitude}`;
        popupHTML.appendChild(latlng);

        // action button
        const button = document.createElement("button");
        button.className = "confirmButton color-white bg-green";
        button.innerHTML = "Add Location";
        button.onclick = () => handleAdd(map, location);
        popupHTML.appendChild(button);

        L.popup()
          .setLatLng(e.latlng)
          .setContent(popupHTML)
          .openOn(map);
      };

      map.on("click", handleClick);

      return () => {
        map.off("click", handleClick); // Clean up the event listener
      };
    }, [map]);

    return null; // This component does not render anything
  };

  const RemoveLocationHandler = () => {
    const map = useMap(); // Hook to access the map instance

    useEffect(() => {
      map.eachLayer(layer => {
        if (layer instanceof L.Marker) {
          layer.remove();
        }
      });
      mapMarkers.forEach((marker: L.Marker) => {
        marker.addTo(map);
      });
    }, [map]);

    return null; // This component does not render anything
  };

  return (
    <div>
      {loading && (
        <CircularProgress
          size={100}
          sx={{ marginLeft: "-50px", marginTop: "-50px" }}
          className="loadingStatus"
          color="primary"
        />
      )}
      <div style={loading ? { pointerEvents: "none", opacity: 0.8 } : {}}>
        {!isPending && (
          <MapContainer center={[37.0902, -95.7129]} zoom={5} className="map">
            <TileLayer
              url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
              minZoom={3}
            />
            <GeoJSON
              data={polygons as GeoJsonObject}
              style={geoJsonlayerStyle}
            />
            <RemoveLocationHandler />
            <ClickHandler />
          </MapContainer>
        )}
      </div>
    </div>
  );
}
