import { useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import { CostsMarker, Location } from "../../../types/interfaces";

import "./DataTableCard.css";

export interface DataTableCardPropsInterface {
  color: string;
  content: CostsMarker;
  onClearClick: () => void;
}

export function DataTableCard({
  color,
  content,
  onClearClick
}: DataTableCardPropsInterface) {
  const displayLatLong = (location: Location) => {
    // Format lattitude with cardinal direction
    const displayLatitude =
      location.lat > 0
        ? `${location.lat.toFixed(4)}° N`
        : `${Math.abs(location.lat).toFixed(4)}° S`;
    const displayLongitude =
      location.lng > 0
        ? `${location.lng.toFixed(4)}° E`
        : `${Math.abs(location.lng).toFixed(4)}° W`;

    return `${displayLatitude}, ${displayLongitude}`;
  };

  const TooltipIcon = () => {
    return (
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.25 12.5H8.75V7.5H7.25V12.5ZM7.9956 6C8.20687 6 8.38542 5.92855 8.53125 5.78565C8.67708 5.64273 8.75 5.46565 8.75 5.2544C8.75 5.04313 8.67855 4.86458 8.53565 4.71875C8.39273 4.57292 8.21565 4.5 8.0044 4.5C7.79313 4.5 7.61458 4.57145 7.46875 4.71435C7.32292 4.85727 7.25 5.03435 7.25 5.2456C7.25 5.45687 7.32145 5.63542 7.46435 5.78125C7.60727 5.92708 7.78435 6 7.9956 6ZM8.00575 16.5C6.90469 16.5 5.86806 16.2917 4.89583 15.875C3.92361 15.4583 3.07292 14.8854 2.34375 14.1562C1.61458 13.4271 1.04167 12.5767 0.625 11.605C0.208333 10.6334 0 9.59519 0 8.49046C0 7.38571 0.208333 6.35069 0.625 5.38542C1.04167 4.42014 1.61458 3.57292 2.34375 2.84375C3.07292 2.11458 3.92332 1.54167 4.89496 1.125C5.86661 0.708333 6.90481 0.5 8.00954 0.5C9.11429 0.5 10.1493 0.708333 11.1146 1.125C12.0799 1.54167 12.9271 2.11458 13.6562 2.84375C14.3854 3.57292 14.9583 4.42169 15.375 5.39008C15.7917 6.35849 16 7.39321 16 8.49425C16 9.59531 15.7917 10.6319 15.375 11.6042C14.9583 12.5764 14.3854 13.4271 13.6562 14.1562C12.9271 14.8854 12.0783 15.4583 11.1099 15.875C10.1415 16.2917 9.10679 16.5 8.00575 16.5ZM8 15C9.80556 15 11.3403 14.3681 12.6042 13.1042C13.8681 11.8403 14.5 10.3056 14.5 8.5C14.5 6.69444 13.8681 5.15972 12.6042 3.89583C11.3403 2.63194 9.80556 2 8 2C6.19444 2 4.65972 2.63194 3.39583 3.89583C2.13194 5.15972 1.5 6.69444 1.5 8.5C1.5 10.3056 2.13194 11.8403 3.39583 13.1042C4.65972 14.3681 6.19444 15 8 15Z"
          fill="currentColor"
        />
      </svg>
    );
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#08291A"
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#08291A",
      color: "#fff",
      maxWidth: 300,
      border: "none",
      padding: "1rem"
    }
  }));

  return (
    <div className="dataTableCard">
      <div className="dataTableCard__header">
        <div>
          <div className="dataTableCard__header__title">
            <div style={{ color: color }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 57.5">
                <path
                  fill="#fff"
                  d="M23 57.5Q11.428 47.653 5.714 39.208T0 23.575Q0 12.794 6.936 6.4A22.975 22.975 0 0 1 23 0a22.975 22.975 0 0 1 16.064 6.4Q46 12.794 46 23.575q0 7.188-5.714 15.633T23 57.5Z"
                />
                <path
                  fill="currentColor"
                  d="M23 28.221a4.858 4.858 0 0 0 4.844-4.844A4.858 4.858 0 0 0 23 18.533a4.859 4.859 0 0 0-4.844 4.844A4.858 4.858 0 0 0 23 28.221Zm0 24.221q-9.749-8.3-14.563-15.411t-4.814-13.17q0-9.083 5.843-14.472A19.356 19.356 0 0 1 23 4a19.356 19.356 0 0 1 13.534 5.389q5.843 5.389 5.843 14.472 0 6.055-4.814 13.17T23 52.443Z"
                />
              </svg>
            </div>
            <h3 className="title type-sm-bold">{content.location.name}</h3>
          </div>
          <div className="dataTableCard__header__latlng">
            <div className="type-xs color-light-green">
              {displayLatLong(content.location)}
            </div>
          </div>
        </div>
        <button
          className="dataTableCard__header__button"
          onClick={onClearClick}
        >
          <div className="type-xs-2 color-light-green">Clear</div>
        </button>
      </div>
      <div className="dataTableCard__content">
        <div className="dataTableCard__content__row color-light-green">
          <HtmlTooltip
            arrow
            placement="bottom-start"
            enterTouchDelay={0}
            title={
              <div>
                <h3 className="type-sm-bold">LCOE</h3>
                <div className="type-sm">
                  Levelized cost of energy, expressed as U.S. dollars per
                  megawatt hour ($/MWh).
                </div>
              </div>
            }
          >
            <div className="row-label">
              <div className="type-sm">LCOE</div>
              <TooltipIcon />
            </div>
          </HtmlTooltip>
          <div className="row-datapoint color-green">${content.lcoe}/MWh</div>
        </div>
        <div className="dataTableCard__content__row color-light-green">
          <HtmlTooltip
            arrow
            placement="bottom-start"
            enterTouchDelay={0}
            title={
              <div>
                <h3 className="type-sm-bold">Gradient</h3>
                <div className="type-sm">
                  The rate of change in temperature with respect to increasing
                  depth, expressed as (°C/km).
                </div>
              </div>
            }
          >
            <div className="row-label">
              <div className="type-sm">Gradient</div>
              <TooltipIcon />
            </div>
          </HtmlTooltip>
          <div className="row-datapoint color-green">
            {content.gradient} °C/km
          </div>
        </div>
        <div className="dataTableCard__content__row color-light-green">
          <HtmlTooltip
            arrow
            placement="bottom-start"
            enterTouchDelay={0}
            title={
              <div>
                <h3 className="type-sm-bold">Target Temperature</h3>
                <div className="type-sm">
                  The temperature at which the LCOE is lowest.
                </div>
              </div>
            }
          >
            <div className="row-label">
              <div className="type-sm">Target Temperature</div>
              <TooltipIcon />
            </div>
          </HtmlTooltip>
          <div className="row-datapoint color-green">
            {content.temperature}°C
          </div>
        </div>
        <div className="dataTableCard__content__row color-light-green">
          <HtmlTooltip
            arrow
            placement="bottom-start"
            enterTouchDelay={0}
            title={
              <div>
                <h3 className="type-sm-bold">Target Depth</h3>
                <div className="type-sm">
                  The depth needed to drill at which the LCOE is lowest, based
                  on the gradient and target temperature.
                </div>
              </div>
            }
          >
            <div className="row-label">
              <div className="type-sm">Target Depth</div>
              <TooltipIcon />
            </div>
          </HtmlTooltip>
          <div className="row-datapoint color-green">{content.depth} km</div>
        </div>
        <div className="dataTableCard__content__row color-light-green">
          <HtmlTooltip
            arrow
            placement="bottom-start"
            enterTouchDelay={0}
            title={
              <div>
                <h3 className="type-sm-bold">Depth to Basement</h3>
                <div className="type-sm">
                  The depth at which we will switch from conventional drilling
                  methods and technology to our breakthrough millimeter wave
                  (MMW) drilling platform. Basement rock is the layer of
                  crystalline rock—usually granite or basalt—below the
                  sedimentary basin.
                </div>
              </div>
            }
          >
            <div className="row-label">
              <div className="type-sm">Depth to Basement</div>
              <TooltipIcon />
            </div>
          </HtmlTooltip>
          <div className="row-datapoint color-green">
            {content.depthToBasement} km
          </div>
        </div>
      </div>
    </div>
  );
}
