export const Colors = {
  slate: {
    tableColor: '#0066FF',
    chartColor: '#0066FF',
  },
  green: {
    tableColor: '#00B8D4',
    chartColor: '#00B8D4'
  },
  aqua: {
    tableColor: '#6200EA',
    chartColor: '#6200EA',
  },
  lightSlate: {
    tableColor: '#AA00FF',
    chartColor: '#AA00FF'
  },
  orange: {
    tableColor: '#00AE48',
    chartColor: '#00AE48'
  },
  red: {
    tableColor: '#64DD17',
    chartColor: '#64DD17'
  },
};
