import React from "react";
import { useState } from "react";
import {
  LineChart,
  Tooltip,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer
} from "recharts";
import { useRecoilValue } from "recoil";
import { CircularProgress } from "@mui/material";
import { DatasetSelector, LoadingState } from "../../store/store";
import { CustomDot } from "./components/CustomDot";

// @ts-ignore
import Spline from "cubic-spline";

import "./Chart.css";

import { CostsMarker } from "../../types";

function interpolateData(data: CostsMarker[]) {
  // Extract temperature (xs) and lcoe (ys) values from the data points
  const xs = data.map(point => point.temperature);
  const ys = data.map(point => point.lcoe);

  // Create a new spline interpolation using the temperature and lcoe values
  const spline = new Spline(xs, ys);

  const interpolatedData = [];
  // Interpolate data points in steps of 0.1 within the range of temperatures
  for (let i = xs[0]; i <= xs[xs.length - 1]; i += 0.1) {
    const y = spline.at(i);
    interpolatedData.push({ temperature: i, lcoe: y });
  }

  return interpolatedData;
}

export function Chart() {
  const datasets = useRecoilValue(DatasetSelector);
  const loading = useRecoilValue(LoadingState);
  const [activePayload, setActivePayload] = useState<{
    payload: any;
    rest: any;
  } | null>(null);

  const handleMouseEnter = (payload: any, rest: any) => {
    setActivePayload({ payload, rest });
  };

  const handleMouseLeave = () => {
    setActivePayload(null);
  };

  const CustomTooltip = () => {
    if (activePayload) {
      return (
        <div className="customTooltip">
          <h4 className="type-sm-bold">
            {activePayload.payload.location.name}
          </h4>
          <div className="type-sm">
            <span className="color-light-green">LCOE:</span>{" "}
            {activePayload.payload.lcoe} $/MWh
          </div>
          <div className="type-sm">
            <span className="color-light-green">Target Temperature:</span>{" "}
            {activePayload.payload.temperature}°C
          </div>
          <div className="type-sm">
            <span className="color-light-green">Target Depth:</span>{" "}
            {activePayload.payload.depth} km
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="chart">
      <div className="chart__header">
        <h2 className="type-sm-bold mb-4">Quaise LCOE</h2>
      </div>
      <div className="chart__content">
        <p className="yAxisLabel type-xs-2 color-light-green">LCOE ($/MWh)</p>
        <div className="chart__render">
          <ResponsiveContainer>
            <LineChart>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="temperature"
                type="number"
                domain={[0, 500]}
                minTickGap={0}
                ticks={[0, 100, 200, 300, 400, 500]}
                allowDataOverflow={true}
              />
              <YAxis
                dataKey="lcoe"
                className="YAxis"
                minTickGap={0}
                type="number"
                domain={[0, 200]}
                ticks={[0, 20, 40, 60, 80, 100, 120, 140, 160, 180, 200]}
                allowDataOverflow={true}
              />
              <Tooltip isAnimationActive={false} content={<CustomTooltip />} />
              {datasets.map((dataset, index) => {
                // Filter out data points with null lcoe or temperature
                const filteredData = dataset.data.filter(
                  point => point.lcoe !== null && point.temperature !== null
                );

                // Find the index of the lowest point in the filtered data
                const lowestPointIndex = filteredData.findIndex(
                  point => point.lowestPoint
                );

                // Get data points before and including the lowest point
                const dataBeforeLowestPoint = filteredData.slice(
                  0,
                  lowestPointIndex + 1
                );

                // Interpolate all points
                const interpolatedData = interpolateData(filteredData);

                // Slice the interpolated data from the lowest point onwards to draw the dashed line
                const dataAfterLowestPoint = interpolatedData.slice(
                  interpolatedData.findIndex(
                    point =>
                      point.temperature >=
                      filteredData[lowestPointIndex].temperature
                  )
                );

                return (
                  <React.Fragment key={index}>
                    {dataAfterLowestPoint.length > 0 && (
                      <Line
                        type="natural"
                        stroke={dataset.chartColor}
                        strokeWidth={3}
                        strokeDasharray="3 3"
                        dataKey="lcoe"
                        data={dataAfterLowestPoint}
                        name={`Line ${index + 1} - Dashed`}
                        activeDot={false}
                        isAnimationActive={false}
                        dot={
                          <CustomDot
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            active={
                              activePayload &&
                              activePayload.rest.dataKey === "lcoe" &&
                              activePayload.rest.index === index
                            }
                          />
                        }
                      />
                    )}
                    <Line
                      type="monotone"
                      stroke={dataset.chartColor}
                      strokeWidth={3}
                      dataKey="lcoe"
                      data={dataBeforeLowestPoint}
                      name={`Line ${index + 1} - Solid`}
                      activeDot={false}
                      isAnimationActive={false}
                      dot={
                        <CustomDot
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          active={
                            activePayload &&
                            activePayload.rest.dataKey === "lcoe" &&
                            activePayload.rest.index === index
                          }
                        />
                      }
                    />
                  </React.Fragment>
                );
              })}
            </LineChart>
          </ResponsiveContainer>
        </div>
        <p className="xAxisLabel type-xs-2 color-light-green">
          Reservoir Temperature (°C)
        </p>
        {loading && (
          <CircularProgress
            size={60}
            className="loadingStatus"
            color="primary"
          />
        )}
      </div>
    </div>
  );
}
