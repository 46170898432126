import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AxiosProvider, useAxios } from "./context/axios";

import Login from "./pages/Login";
import { ContentPage } from "./pages/ContentPage";
import { Admin } from "./pages/Admin";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import { SnackbarAlert } from "./components/SnackbarAlert/SnackbarAlert";

import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";

import "./App.css";

const theme = createTheme({
  typography: {
    fontFamily: "NeueHaasGroteskText Pro"
  }
});

const queryClient = new QueryClient();

function App() {
  const axios = useAxios();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/health`)
      .then(response => console.log("Health status: ", response.data))
      .catch(error => console.error("There was an error!", error));
  }, [axios]);

  return (
    <BrowserRouter basename="/lcoe/calculator">
      <QueryClientProvider client={queryClient}>
        <AxiosProvider>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <div className="App">
              <Routes>
                <Route path="/" element={<ContentPage />} />
                <Route path="/login" element={<Login />} />
                <Route element={<ProtectedRoute />}>
                  <Route path="/admin" element={<Admin />} />
                </Route>
              </Routes>
              <SnackbarAlert />
            </div>
          </ThemeProvider>
        </AxiosProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
